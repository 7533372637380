.App {

  min-height: 100vh;
  max-width: 100vw;
  
  font-size: calc(10px + 1vmin);
  overflow-x: hidden;
  padding:20px;
}

.tltp{
  border-bottom: 1px dashed black;
}

.Inline {
  display: flex;
  flex-direction: row;
}

.SendBtn{
  background-color: #00bcd4;
  cursor: pointer;
  position: fixed;
  bottom: 0;
  width: 100vw;
  left: 0;
  height: 80px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:30px;
  font-weight:900;
  z-index: 9999999;

}

.SendBtn:hover{
  background-color: black;
}

h2,h3{
  text-align: left;
  color: rgba(0,0,0,.6);
}

h1{
  text-align: left;
  margin: 0;
  padding-top: 50px;
}

.contactCard{
  font-size: 12px;
  text-align: center;
}


.contactCard li{
  list-style: none;
}